import { defineStore } from 'pinia'

import { Filter, TipsState, Filters, Hit } from '~/js/interface/tip'
import { QueryParams } from '~/interfaces/query-parameters'

import { useSearchParameters, useTypesenseClient } from '~/js/composables/typesense'

export const useTipsStore = defineStore('tips', {
    state: (): TipsState => ({
        loading: true,
        facets: [],
        filters: {
            searchQuery: '',
            filterQuery: '',
            sector: {
                field: 'sector',
                value: null,
                combination: '&&'
            },
            tags: {
                field: 'tags',
                values: [],
                combination: '&&'
            },
        },
        pagination: {
            current: 0,
            totalPages: 0,
            totalResults: 0,
            foundResults: 0,
            itemsPerPage: 5,
        },
        sorting: {
            type: 'title',
            order: 'asc',
        },
        index: `g4j_${window.typesense_environment}_tips`,
        tips: null,
        randoms: null,
    }),
    actions: {
        async fetch(reset = false, queryParams?: Array<QueryParams>) {
            this.loading = true
            const fetchRandoms = this.filters.sector.value != '' || this.filters.tags.values.length > 0

            // set URL query parameters if existing
            if (queryParams) {
                this.setQueryFilters(queryParams)
            }

            if (reset) {
                this.pagination.current = 0
            } else {
                this.pagination.current += 1
            }

            const client = useTypesenseClient()
            const searchParameters = useSearchParameters(this.filters, this.sorting)

            const searchRequests = {
                searches: [
                    {
                        collection: this.index,
                        page: this.pagination.current,
                        per_page: this.pagination.itemsPerPage,
                        ...searchParameters
                    }
                ]
            }

            // if (fetchRandoms && reset) {
            //     searchRequests.searches.push({
            //         sort_by: "_text_match:desc",
            //         collection: this.index,
            //         q: "*",
            //         page: 1,
            //         per_page: this.pagination.itemsPerPage,
            //         filter_by: this.filters.filterQuery.replaceAll(':[',':![').replaceAll('||','&&'), // exclude the sector and tags given
            //     })
            // }

            await client
                .multiSearch
                .perform(searchRequests)
                .then((results: any) => {
                    setTimeout(() =>{

                        const filteredResults = results.results[0]
                        const facets = results.results[0].facet_counts

                        this.facets = facets
                        this.pagination.current = filteredResults.page
                        this.pagination.totalPages =  this.pagination.itemsPerPage ? Math.ceil(filteredResults.found / this.pagination.itemsPerPage) : 0
                        this.pagination.foundResults = filteredResults.found
                        this.pagination.totalResults = filteredResults.out_of

                        if (filteredResults.hits) {
                            const hits: Array<Hit> = filteredResults.hits

                            // set results to the projects if it's a reset otherwise add the filteredResults to the existing ones
                            if (reset) {
                                this.tips = hits
                            } else {
                                this.tips = [...(this.tips || []), ...hits]
                            }
                        }

                        // set randoms to show aside of the jobs
                        if (reset) {
                            let sectorPosts:Array<Hit> = []
                            let randomizePosts:Array<Hit> = []

                            if (this.filters.sector.value) {
                                sectorPosts = filteredResults?.hits.filter(doc => doc.document.sector == this.filters.sector.value).sort( () => Math.random() - 0.5) 
                                randomizePosts = filteredResults?.hits.filter(doc => doc.document.sector == '').sort( () => Math.random() - 0.5) 
                            } else {
                                randomizePosts = [...filteredResults?.hits ?? []].sort( () => Math.random() - 0.5) 
                            }

                            // merge the filtered results with the hits
                            this.randoms = [...sectorPosts ?? [], ...randomizePosts ?? []]
                        }

                        this.loading = false
                    }, 1000)
                })
        },
        setPagination(page) {
            this.pagination.current = page
            this.fetch()
        },
        setSearch(value: string) {
            this.filters.searchQuery = value

            this.fetch(true)
        },
        setFilter(type: keyof Filters, value: string, fetch = true) {
            const settedFilter: Filter | string = this.filters[type] ?? null

            if (typeof settedFilter !== 'string') {
                if (settedFilter.hasOwnProperty('value')) {
                    settedFilter.value = value
                }

                if (settedFilter.hasOwnProperty('values')) {
                    if (settedFilter.values.indexOf(value) < 0) {
                        settedFilter.values.push(value)
                    } else {
                        settedFilter.values = settedFilter.values.filter((item: string) => item !== value)
                    }
                }
            }

            this.setFiltersQuery()

            if (fetch) {
                this.fetch(true)
            }
        },
        setFiltersQuery() {
            this.filters.filterQuery = ''

            if (this.filters.sector.value) {
                const combination = this.filters.tags.values.length > 0 ? '||' :  this.filters.sector.combination
                this.filters.filterQuery += this.filters.sector.field + ':[' + this.filters.sector.value + '] ' + combination + ' '
            }

            if (this.filters.tags.values.length > 0) {
                this.filters.filterQuery += this.filters.tags.field + ':[' + this.filters.tags.values.toString() + '] ' + this.filters.tags.combination + ' '
            }

            // remove empty ' || '
            const pipesCombination = '&& '
            if (this.filters.filterQuery.lastIndexOf(pipesCombination) >= this.filters.filterQuery.length - 4) {
                this.filters.filterQuery = this.filters.filterQuery.substring(0, this.filters.filterQuery.lastIndexOf(pipesCombination))
            }
        },
        setQueryFilters(queryParams: Array<QueryParams>) {
            for (const key in queryParams as QueryParams[]) {
                const values = queryParams[key].values
                let type: string = queryParams[key].type

                if (values) {

                    const filter = this.filters[type as keyof Filters]

                    if (filter) {
                        if (filter.hasOwnProperty('values')) {
                            this.filters[type as keyof Filters].values = values.map((item: { name: string }) => item.name)
                        }

                        if (filter.hasOwnProperty('value')) {
                            if (typeof this.filters[type as keyof Filters].value === 'boolean') {
                                this.filters[type as keyof Filters].value = (values.map((item: { name: string }) => item.name)[0] === 'true')
                            } else {
                                this.filters[type as keyof Filters].value = values.map((item: { name: string }) => item.name)[0]
                            }
                        }

                        if (typeof filter == 'string') {
                            this.filters[type as keyof Filters] = values.map((item: { name: string }) => item.name)[0]
                        }
                    }
                }
            }

            this.setFiltersQuery()
        }
    }
})