<script setup lang="ts">
import { onMounted, computed, ref, reactive } from 'vue'
import { storeToRefs } from 'pinia'

import { getGlobalTranslation } from '~/js/composables/translations'
import { useSetQueryParams } from '~/js/composables/query-parameters'

import { useJobsStore } from '~/js/stores/jobs'
import { useBlogStore } from '~/js/stores/blogs'
import { useTipsStore } from '~/js/stores/tips'
import { Category } from '~/js/interfaces/job'

import AlertNoResults from '~/vue/_molecules/alerts/AlertNoResults.vue'
import BadgePrimary from '~/vue/_atoms/badges/BadgePrimary.vue'
import ButtonSecondary from '~/vue/_atoms/buttons/ButtonSecondary.vue'
import CalloutTip from '~/vue/_molecules/callouts/CalloutTip.vue'
import CardBlog from '~/vue/_molecules/cards/CardBlog.vue'
import CardBlogGrid from '~/vue/_molecules/cards/CardBlogGrid.vue'
import CardJob from '~/vue/_molecules/cards/CardJob.vue'
import DrawerLogin from '~/vue/_organisms/drawers/DrawerLogin.vue'
import FilterJobs from '~/vue/_organisms/filters/FilterJobs.vue'
import SkeletonBlogImage from '~/vue/_molecules/skeletons/SkeletonBlogImage.vue'
import SkeletonCallout from '~/vue/_molecules/skeletons/SkeletonCallout.vue'
import SkeletonJob from '~/vue/_molecules/skeletons/SkeletonJob.vue'
import TextH2 from '~/vue/_atoms/texts/TextH2.vue'

interface Props {
    contractTypeCategories?: Array<Category>,
    exclude?: String | null,
    limit?: Number | null,
    office?: String | null,
    officeStatus?: String | null,
    preselectedCity?: String | null,
    preselectedLocation?: String | null,
    preselectedSectors?: Array<Number> | null,
    sectorCategories?: Array<Category> | null,
    showFilters?: boolean
    showHeading?: boolean,
    showMore?: boolean
    showPerfectMatchOnly?: boolean
    showSector?: boolean
    showTipsAndBlog?: boolean|number,
    tags?: Array<Category> | null,
    useQueryParams?: boolean
    utilities?: String
    workRegimeCategories?: Array<Category>,
}

const props = withDefaults(defineProps<Props>(), {
    contractTypeCategories: null,
    exclude: null,
    limit: null,
    office: null,
    officeStatus: 'live',
    preselectedCity: null,
    preselectedLocation: null,
    preselectedSectors: null,
    sectorCategories: null,
    showFilters: true,
    showHeading: true,
    showMore: true,
    showPerfectMatchOnly: false,
    showSector: true,
    showTipsAndBlog: true,
    tags: null,
    useQueryParams: false,
    utilities: '',
    workRegimeCategories: null,
})

const jobsStore = useJobsStore()
const blogStore = useBlogStore()
const tipsStore = useTipsStore()
const { posts, loading, pagination } = storeToRefs(jobsStore)
const { randoms:blogs, loading:loadingBlogs } = storeToRefs(blogStore)
const { randoms:tips, loading:loadingTips } = storeToRefs(tipsStore)
const showLoginDrawer = ref(false)
const cardsRef = ref(new Map<number, InstanceType<typeof CardJob>>())
const jobIdToFavourite = ref(null)
const favouriteJobs = ref([])
const favouriteSectors = ref([])

const onLoadMore = () => {
    jobsStore.fetch(false)
}

const blog = (index) => {
    return blogs.value ? blogs.value[index]?.document ?? blogs.value[0]?.document ?? null : null
}

const tip = (index) => {
    return tips.value ? tips.value[index]?.document ?? tips.value[0]?.document ?? null : null
}

const vacancyCount = computed(() => {
    return Intl.NumberFormat('nl-BE', { style: 'decimal'}).format(pagination.value?.foundResults ?? 0,)
})

const presetFilters = () => {
    if (props.limit) {
        jobsStore.setPaginationPerPage(props.limit, false)
    }

    if (props.exclude) {
        jobsStore.setHiddens(props.exclude, false)
    }

    if (props.preselectedSectors) {
        props.preselectedSectors.forEach((sector: Number) => {
            jobsStore.setFilter('sectors', sector, false)

            if (props.showTipsAndBlog) {
                blogStore.setFilter('sector', sector, false)
                tipsStore.setFilter('sector', sector, false)
            }
        })
    }

    if (props.office) {
        jobsStore.setFilter('offices', props.office, false)
    }

    if (props.preselectedLocation && props.preselectedCity) {
        const location = {
            city: props.preselectedCity,
            coordinates: props.preselectedLocation.split(',')
        }

        jobsStore.setLocation(location, false, false)
    }

    if (props.tags && props.showTipsAndBlog) {
        props.tags.forEach(tag => {
            blogStore.setFilter('tags', tag, false, false)
            tipsStore.setFilter('tags', tag, false)
        })
    }

    jobsStore.setFilter('officeStatus', props.officeStatus, false)
    jobsStore.setQueryParams(props.useQueryParams)
}

const handleShowLoginDrawer = (show, jobId = null) => {
    jobIdToFavourite.value = jobId
    showLoginDrawer.value = show
}

const handleLogIn = () => {
    fetchFavourites(true)
    showLoginDrawer.value = false
}

const fetchFavourites = (applyLikeToJob = false) => {
    // Fetch favourite jobs with userId as a query parameter
    fetch(`/actions/site-module/favourite/get-favourites`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
        },
    })
        .then(response => response.json())
        .then(data => {
            if (data?.favourites) {
                favouriteJobs.value = data.favourites
                favouriteSectors.value = data.sectors

                if (applyLikeToJob) {
                    if (cardsRef.value.get(jobIdToFavourite.value)) {
                        cardsRef.value.get(jobIdToFavourite.value).applyLike(data.favourites)
                        jobIdToFavourite.value = null
                    }
                }
            }
        })
}

onMounted(async () => {
    // get url parameters
    const queryParameters = useSetQueryParams()

    // set fetch filters
    blogStore.setQueryParams(false)
    presetFilters()

    // fetch user favourites
    fetchFavourites()

    // fetch blogs and tips
    if (props.showTipsAndBlog) {
        blogStore.fetch(true, true)
        tipsStore.fetch(true)
    }
    
    // fetch jobs
    jobsStore.fetch(true,queryParameters)
    jobsStore.popularQueries()
})
</script>

<template>
    <section 
        :class="[
            'space-y-8 md:space-y-12 lg:space-y-16 pb-20',
            utilities
        ]"
    >
        <FilterJobs 
            v-if="showFilters"
            :contract-types="contractTypeCategories"
            :sectors="sectorCategories"
            :show-sector="showSector"
            :work-regimes="workRegimeCategories"
        />

        <div>
            <div class="flex flex-wrap items-center sm:items-center mb-6 scroll-mt-20 gap-4"  id="vue-job-results" v-if="showHeading">
                <h2>
                    <TextH2>
                        {{ getGlobalTranslation('Resultaten') }}
                    </TextH2>
                </h2>
                <BadgePrimary 
                    :content="`${vacancyCount} vacature(s)`" 
                    color="green" 
                    utilities="h-fit !text-xs md:!text-sm lg:!text-lg"
                />
            </div>

            <div :class="showTipsAndBlog ? 'grid lg:grid-cols-3 md:gap-8 lg:gap-20' : 'w-full'">
                <div class="space-y-6 lg:col-span-2 w-full max-w-full overflow-hidden ">
                    
                    <AlertNoResults v-if="(posts == null || (posts && posts.filter(post => post.document?.type !== 'blog' && post.document?.type !== 'tip').length === 0)) && !loading" />

                    <ul 
                        v-if="posts"
                        class="space-y-6"
                    >
                        <li
                            v-for="(post, i) in posts"
                        >
                            <CardJob 
                                v-if="post?.document?.type != 'blog' && post?.document?.type != 'tip'"
                                :card="post?.document"
                                :distance="post?.geo_distance_meters?.coordinates"
                                :ref="el => cardsRef.set(post?.document?.id, el)"
                                :favourite-jobs="favouriteJobs"
                                :favourite-sectors="favouriteSectors"
                                @showLoginDrawer="handleShowLoginDrawer"
                            />
                        </li>
                    </ul>

                    <SkeletonJob 
                        v-if="loading"
                        v-for="i in pagination.itemsPerPage"
                        :key="i"
                    />

                    <ButtonSecondary
                        v-if="!loading && (pagination.current < pagination.totalPages && pagination.current != pagination.totalPages) && showMore"
                        utilities="mx-auto !block mt-4"
                        type="button"
                        :content="getGlobalTranslation('Toon meer resultaten')"
                        @click="onLoadMore"
                    />
                </div>

                <aside class="block relative mt-20 md:mt-0">
                    <div class="sticky top-20 space-y-6">
                        <CardBlogGrid 
                            v-if="blog(pagination.current-1) && showTipsAndBlog"
                            :card="blog(pagination.current-1)"
                            utilities="hidden lg:block"
                        />
                        <CardBlog 
                            v-if="blog(pagination.current-1) && showTipsAndBlog"
                            :card="blog(pagination.current-1)"
                            utilities="lg:hidden"
                        />
                        <SkeletonBlogImage
                            v-if="loadingBlogs && showTipsAndBlog"
                        />

                        <CalloutTip 
                            v-if="tip(pagination.current-1) && showTipsAndBlog"
                            :card="tip(pagination.current-1)"
                        />
                        <SkeletonCallout
                            v-if="loadingTips && showTipsAndBlog"
                        />
                    </div>
                </aside>
            </div>
        </div>

        <!-- v-if="pagination.current < pagination.totalPages && pagination.current != pagination.totalPages" -->
        <!-- @click="onLoadMore" -->

        <DrawerLogin
            @showLoginDrawer="() => handleShowLoginDrawer(false)"
            @loggedIn="handleLogIn"
            :title="getGlobalTranslation('Log in om je favorieten op te slaan')"
            :description="getGlobalTranslation('Wil je jouw favoriete vacatures eenvoudig opslaan en later terugvinden? Log in op je account en geniet van een gepersonaliseerde ervaring.')"
            :show="showLoginDrawer"
        />
        
    </section>
</template>